import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { SubmitHandler } from "react-hook-form";
import AddMoreWebsites from "./AddMoreWebsites";
import { useCallback, useEffect, useState } from "react";
import { ResourceIVInfo } from "../../app/models/RCIVResources";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import { ExpandCircleDown } from "@mui/icons-material";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useTranslation } from "../../translation/translate";

export default function WebsitesProvidingInfo() {
  const { user } = useAppSelector((state) => state.account);
  const [newWeb, setNewWeb] = useState(false);
  const [webData, setWebData] = useState<ResourceIVInfo[]>([]);
  const [addBtn, setAddBtn] = useState("flex");
  const [openDialog, setOpenDialog] = useState({ show: false, id: "" });
  const [websiteID, setWebsiteID] = useState<number>();
  const [websiteValue, setWebsiteValue] = useState<string>("");
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [descriptionBLValue, setDescriptionBLValue] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean | null>(null);
  const [fileData, setFileData] = useState<FileInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getTranslation } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const handleClick = (id: any) => {
    setOpenDialog({ show: true, id: id });
  };
  const handleClose = () => {
    setOpenDialog({ show: false, id: "" });
  };

  const fetchData = useCallback(async () => {
    const dataFiles = await agent.FileUpload.GetFileInfo(
      "RCIV4",
      user?.portfolioId ?? 0
    );
    setFileData(dataFiles);
    if (user && user.portfolioId) {
      const data = await agent.WebsiteArticles.GetWebsiteArticles(
        user.portfolioId
      );

      setWebData(data);
    }
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    fetchData(); // Initial data fetch when the component mounts
  }, []); // Fetch data when user changes

  const handleNewWeb = () => {
    setNewWeb((prev) => !prev);
  };

  const onSubmit: SubmitHandler<ResourceIVInfo> = async (data) => {
    if (user && user.portfolioId) {
      await agent.WebsiteArticles.PostWebsiteArticles(user?.portfolioId, data);
      setWebData([...webData, data]);
    }
    setNewWeb(false);
    setIsEdit(false);
    fetchData();
  };

  const counter = (id: string) => {
    const arr = webData
      .map((item: any) => {
        return item.id;
      })
      .indexOf(id);
    return arr + 1;
  };
  useEffect(() => {
    const arr = webData.length;
    if (arr === 10) {
      setAddBtn("none");
    } else {
      if (arr !== 9) {
        setAddBtn("flex");
      }
    }
  }, [webData]);

  const handleRemove = async (id: any, counterId: number) => {
    const newList = webData.filter((item) => item.id !== id);
    setWebData(newList);
    await agent.WebsiteArticles.DeleteWebsiteArticle(id);
    setOpenDialog({ show: false, id: "" });

    const filteredData = fileData.filter(
      (item: { certificateType: string | string[] }) =>
        item.certificateType.includes(counterId.toString())
    );
    for (const item of filteredData) {
      await agent.FileUpload.DeleteFile(item.id);
    }
  };

  function handleDeleteFile(): void {
    fetchData();
  }

  if (isLoading) {
    return null;
  }

  const handleEdit = (id: any) => {
    setIsEdit(true);
    const updateEntry = webData.find((item) => item.id === id);
    if (updateEntry) {
      setWebsiteID(updateEntry.id);
      setDescriptionValue(updateEntry.description);
      setDescriptionBLValue(updateEntry.description2ndLanguage);
      setWebsiteValue(updateEntry.website);
    }
    setNewWeb(true);
  };

  const resetInput = () => {
    setDescriptionValue("");
    setDescriptionBLValue("");
    setWebsiteValue("");
  };

  return (
    <Grid item p={2} bgcolor={"warning.light"}>
      <Typography fontWeight={700}>
        {getTranslation("RCIVWebsitesProvidingInfoLine1")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <Typography display={user?.languageSpecialization ? "show" : "none"}>
            {getTranslation("RCIVWebsitesProvidingInfoLine2")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {getTranslation("RCIVWebsitesProvidingInfoLine3")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {user?.credentialTypeId === 1 &&
              getTranslation("RCIVWebsitesProvidingInfoLine4")}
            {user?.credentialTypeId === 2 &&
              getTranslation("RCIVWebsitesProvidingInfoLine5")}
            {(user?.credentialTypeId === 3 || user?.credentialTypeId === 4) &&
              getTranslation("RCIVWebsitesProvidingInfoLine6")}
          </Typography>
        </Grid>
        {webData.map((sites: any) => (
          <Grid item xs={12} key={sites.id} mb={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandCircleDown color="warning" />}
              >
                <Typography
                  fontSize={20}
                  fontWeight={700}
                  mr={2}
                  color="primary"
                >
                  {counter(sites.id)}
                </Typography>
                <Typography fontSize={18} fontWeight={700}>
                  {sites.website}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={6}>
                  <Typography>
                    {getTranslation("RCIV-4Websites_Website")}
                  </Typography>
                  <Typography sx={{ marginLeft: 3 }}>
                    {sites.website}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                >
                  <Grid item xs={12} mr={isMobile ? 0 : 2}>
                    <Typography>
                      {getTranslation("RCIV-4Websites_Description")}ii
                    </Typography>
                    <Typography sx={{ marginLeft: 3, wordWrap: "normal" }}>
                      {sites.description}
                    </Typography>
                  </Grid>
                  {user?.languageSpecialization && (
                    <Grid item xs={12}>
                      <Typography>
                        
                        {getTranslation(
                          "RCIV-4Websites_Descripción (segundo idioma)"
                        )}
                      </Typography>
                      <Typography sx={{ marginLeft: 3, wordWrap: "normal" }}>
                        {sites.description2ndLanguage}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {fileData
                    .filter((item: { certificateType: string | string[] }) =>
                      item.certificateType.includes(
                        counter(sites.id).toString()
                      )
                    )
                    .map((filteredItem) => (
                      <UploadedFiles
                        fileOrder={fileData.indexOf(filteredItem) + 1}
                        key={filteredItem.id}
                        fileInfo={filteredItem}
                        onDeleteFile={() => handleDeleteFile()}
                      />
                    ))}
                </Grid>

                <Grid
                  item
                  xs={12}
                  mt={2}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Grid item mr={2}>
                    <Button
                      onClick={() => handleEdit(sites.id)}
                      color="warning"
                      variant="outlined"
                    >
                      {getTranslation("Edit")}
                    </Button>
                  </Grid>
                  <Grid item mr={1}>
                    <Button
                      key={sites.id}
                      onClick={() => handleClick(sites.id)}
                      color="warning"
                      variant="outlined"
                    >
                      {getTranslation("Delete")}
                    </Button>
                    <Dialog
                      open={openDialog.show}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete Entry?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {getTranslation("RCIVWebsitesProvidingInfoLine7")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() =>
                            handleRemove(openDialog.id, counter(sites.id))
                          }
                        >
                          {getTranslation("Delete")}
                        </Button>
                        <Button onClick={handleClose} autoFocus>
                          {getTranslation("Cancel")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}

        {newWeb && (
          <AddMoreWebsites
            onSubmit={onSubmit}
            websiteCount={webData.length}
            descriptionValue={descriptionValue}
            setDescriptionValue={setDescriptionValue}
            descriptionBLValue={descriptionBLValue}
            setDescriptionBLValue={setDescriptionBLValue}
            websiteValue={websiteValue}
            setWebsiteValue={setWebsiteValue}
            isEdit={isEdit}
            websiteID={websiteID}
          />
        )}
        {!isEdit && (
          <Grid item display={addBtn} justifyContent="center" xs={12}>
            <Button
              variant="text"
              color="warning"
              onClick={() => {
                resetInput();
                setIsEdit(false);
                handleNewWeb();
              }}
            >
              {newWeb
                ? getTranslation("Cancel")
                : getTranslation("AddWebsiteEntry")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
