import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function HowToUpload() {
  const { getTranslation } = useTranslation();
  return (
    <Grid item container spacing={3} p={1} m={2}>
      <Grid item xs={5}>
        <Typography fontSize={18} fontWeight={700}>
          {getTranslation("Uploading")}
        </Typography>
        <Typography>
          {getTranslation("HowToUploadDownloadUploadingLine1")}
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" alignSelf="start" marginLeft={4}>
              <video width="100%" height="auto" controls>
          <source
            src={require("../../assets/videos/CDA - How to Upload.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Grid>
    </Grid>
  );
}
