import {
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { RCVIUploadForm } from "../../app/models/RCVIUploadForm";
import { yupResolver } from "@hookform/resolvers/yup";
import RCVIValidationSchema from "../../app/validations/RCVIMentalHealthValidation";
import { useTranslation } from "../../translation/translate";

export default function MentalHealthAgency(props: any) {
  const { onsubmit, toggle } = props;
  const { getTranslation } = useTranslation();

  const handleBilingualInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RCVIUploadForm>({
    resolver: yupResolver(RCVIValidationSchema()),
    mode: "onTouched",
  });
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleCancelEntry = () => {
    reset();
    toggle();
  };
  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12}>
        <Typography fontWeight={600}>
          {getTranslation("AtLeastoneAgency")}
        </Typography>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <TextField sx={{ display: "none" }} {...register("id")} value={0} />
        <TextField
          label={getTranslation("RCVIChildhoodAssociationsLine7")}
          {...register("agency")}
          fullWidth
          error={!!errors.agency?.message}
          helperText={errors.agency?.message}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <TextField
          label={getTranslation("Telephone")}
          {...register("telephone")}
          onChange={handleBilingualInput}
          fullWidth
          error={!!errors.telephone?.message}
          helperText={errors.telephone?.message}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <TextField
          label={getTranslation("Website")}
          {...register("website")}
          placeholder="https://www.website.com/"
          fullWidth
          error={!!errors.website?.message}
          helperText={errors.website?.message}
        />
      </Grid>
      <Grid
        item
        display="flex"
        justifyContent="flex-end"
        alignSelf="flex-end"
        xs={12}
        m={2}
      >
        <Button
          variant="contained"
          size="small"
          color="compVI"
          sx={{ marginRight: 4 }}
          onClick={handleSubmit(onsubmit)}
        >
          {getTranslation("ChildhoodAssociationEntry5")}
        </Button>
        <Button variant="outlined" color="compVI" onClick={handleCancelEntry}>
          {getTranslation("ChildhoodAssociationEntry6")}
        </Button>
      </Grid>
    </Grid>
  );
}
