import { ExpandCircleDown, FileUpload } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../app/store/configureStore";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { RCVIAnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const StateAgencyRegulating: React.FC<RCVIAnswersProps> = ({
  answersData,
  onSave,
  onAnswerChange,
}) => {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    name: yup
      .string()
      .max(60, getTranslation("ValidationNameMaxChar"))
      .required(getTranslation("ValidationNameRequired")),
    address: yup
      .string()
      .max(100, getTranslation("ValidationAddressMaxChar"))
      .required(getTranslation("ValidationAddressRequired")),
    telephone: yup
      .string()
      .required(getTranslation("ValidationPhoneNumberRequired"))
      .min(10, getTranslation("ValidationInvalidNumber"))
      .max(11, getTranslation("ValidationInvalidNumber")),
    email: yup
      .string()
      .max(50, getTranslation("ValidationEmailMaxChar"))
      .email(getTranslation("ValidationInvalidEmail")),
    website: yup
      .string()
      .url(getTranslation("ChildhoodAssociationEntryValidationWebsite"))
      .required(getTranslation("ValidationWebsiteRequired")),
  });
  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCVI1a",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);

  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });

  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  const defaultValues = {
    name: answersData.find((answer) => answer.systemCodeId === 269)?.answer,
    address: answersData.find((answer) => answer.systemCodeId === 270)?.answer,
    telephone: answersData.find((answer) => answer.systemCodeId === 271)
      ?.answer,
    email: answersData.find((answer) => answer.systemCodeId === 272)?.answer,
    website: answersData.find((answer) => answer.systemCodeId === 273)?.answer,
    uploadDecision:
      answersData.find((answer) => answer.systemCodeId === 274)?.answer || null,
  };
  const {
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  useEffect(() => {
    fetchData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
  }

  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
    const newValue = event.target.value;
    const systemCodeId = 271;
    onAnswerChange(systemCodeId, newValue);
  };

  return (
    <Grid container p={2} m={1}>
      <Grid item>
        <Typography fontSize={18} fontWeight={700} mb={2}>
          {getTranslation("RCVIStateAgencyRegulatingLine1")}
        </Typography>
        <Typography mb={2}>
          {getTranslation("RCVIStateAgencyRegulatingLine2")}
        </Typography>
      </Grid>
      <Grid item>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandCircleDown sx={{ color: "compVI.main" }} />}
          >
            <Typography fontSize={18} fontWeight={700}>
              {getTranslation("AgencyInformation")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12}>
                <TextField
                  label={getTranslation("RCVI_UploadDocuments_Name")}
                  {...register("name")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 269)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 269;
                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVI_UploadDocuments_Address")}
                  {...register("address")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 270)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 270;

                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.address?.message}
                  helperText={errors.address?.message}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVI_UploadDocuments_Telephone")}
                  {...register("telephone")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 271)
                      ?.answer || ""
                  }
                  onChange={handlePhoneInput}
                  fullWidth
                  error={!!errors.telephone?.message}
                  helperText={errors.telephone?.message}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVI_UploadDocuments_Email")}
                  {...register("email")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 272)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 272;

                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.email?.message}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVI_UploadDocuments_Website")}
                  {...register("website")}
                  placeholder="https://www.website.com/"
                  value={
                    answersData.find((answer) => answer.systemCodeId === 273)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 273;

                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.website?.message}
                  helperText={errors.website?.message}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Typography fontWeight={600}>
                  {getTranslation("UploadDocuments")}
                </Typography>
                <Typography fontWeight={600} mb={2}>
                  {getTranslation("RCVIStateAgencyRegulatingLine3")}
                </Typography>

                <Grid item xs={12} mt={2}>
                  <Button
                    variant="outlined"
                    color="compVI"
                    size="small"
                    sx={{ marginBottom: 2 }}
                    onClick={() => handleOpenDrawer("right", "RCVI1a")}
                  >
                    <FileUpload /> {getTranslation("UploadDocumentation")}
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 6}>
                <Typography fontWeight={600}>
                  {getTranslation("UploadedDocuments")}
                </Typography>
                <Typography variant="subtitle2">
                  {data.length}{" "}
                  {data.length === 1
                    ? getTranslation("file")
                    : getTranslation("files")}{" "}
                  {getTranslation("uploaded")}
                </Typography>
                {fileDataArray()}
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="compVI"
                  onClick={onSave}
                  disabled={!isValid}
                >
                  {getTranslation("Save")}
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
export default StateAgencyRegulating;
