import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const CognitiveDevelopment: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    first: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    second: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const isHomeVisitor = user?.credentialTypeId === 4;
  const firstSystemCodeId = isHomeVisitor ? 55 : 53;
  const secondSystemCodeId = isHomeVisitor ? 56 : 54;

  const defaultValues = {
    first: answersData.find(
      (answer) => answer.systemCodeId === firstSystemCodeId
    )?.answer,
    second: answersData.find(
      (answer) => answer.systemCodeId === secondSystemCodeId
    )?.answer,
  };

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid pb={3}>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("StatementIICognitiveDevelopmentLine1")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("StatementIICognitiveDevelopmentLine2")
            : getTranslation("StatementIICognitiveDevelopmentLine3")}
        </Typography>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" paragraph>
          {getTranslation("WhichLearningExperience")}
        </Typography>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIICognitiveDevelopmentLine4")
              : getTranslation("StatementIICognitiveDevelopmentLine5")
          }
          multiline
          rows={2}
          fullWidth
          {...register("first")}
          name="first"
          value={
            answersData.find(
              (answer) => answer.systemCodeId === firstSystemCodeId
            )?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = firstSystemCodeId;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.first?.message}
          helperText={errors.first?.message}
        />
        <Typography fontSize={13}>{defaultValues.first?.length}/500</Typography>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("StatementIICognitiveDevelopmentLine6")
            : getTranslation("StatementIICognitiveDevelopmentQuestion1")}
        </Typography>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIICognitiveDevelopmentLine7")
              : getTranslation("StatementIICognitiveDevelopmentLine8")
          }
          multiline
          rows={2}
          fullWidth
          {...register("second")}
          name="second"
          value={
            answersData.find(
              (answer) => answer.systemCodeId === secondSystemCodeId
            )?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = secondSystemCodeId;
            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.second?.message}
          helperText={errors.second?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.second?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default CognitiveDevelopment;
