import { Grid, Typography } from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function HowToDownload() {
  const { getTranslation } = useTranslation();
  return (
    <Grid item container spacing={4} p={1} m={2} bgcolor="myVerify.light">
      <Grid item xs={5}>
        <Typography fontSize={18} fontWeight={700}>
          {getTranslation("Downloading")}
        </Typography>
        <Typography>
          {getTranslation("HowToUploadDownloadDownloadingLine1")}
        </Typography>
        <Typography mt={2}>
          {getTranslation("HowToUploadDownloadDownloadingLine2")}{" "}
          <span style={{ fontWeight: 700 }}>
            {getTranslation("MyUploads")}{" "}
          </span>
          {getTranslation("HowToUploadDownloadDownloadingLine3")}{" "}
          <span style={{ fontWeight: 700 }}>
            {getTranslation("ResourceLibrary")}
          </span>{" "}
          screen
        </Typography>
        <Typography mt={2}>
          {getTranslation("HowToUploadDownloadDownloadingLine4")}
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" alignSelf="start" marginLeft={4}>
        <video width="100%" height="auto" controls>
          <source
            src={require("../../assets/videos/CDA - How to Download.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Grid>
    </Grid>
  );
}
