import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import Resource1pt1 from "./Resource1part1";
import { ExpandCircleDown } from "@mui/icons-material";
import Resource1pt2 from "./Resource1part2";
import Resource1pt3 from "./Resource1part3";
import { useTranslation } from "../../translation/translate";
import { useAppSelector } from "../../app/store/configureStore";

export default function Resource1Uploads() {
  const { getTranslation } = useTranslation();
  const { user } = useAppSelector((state) => state.account);
  return (
    <Grid item xs={12} mt={3}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "myVerify.main" }} />}
        >
          <Typography fontWeight={700} fontSize={18}>
            {getTranslation("ResourceCollectionI")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Resource1pt1 />
          {user?.credentialTypeId != 4 && <Resource1pt2 />}
          <Resource1pt3 />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
