import { ExpandCircleDown } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function Espanol() {
  const { getTranslation } = useTranslation();
  const downloadFCCQuestionnaire = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireSummary.pdf`;
    link.href =
      "../docs/SP_FCC_CS_book_Family Questionnaires Summary Sheet.pdf";
    link.click();
  };

  const downloadITQuestionnaire = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireSummary.pdf`;
    link.href = "../docs/SP_IT_CS_book_Family Questionnaires Summary Sheet.pdf";
    link.click();
  };

  const downloadHVQuestionnaire = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireSummary.pdf`;
    link.href = "../docs/SP_HV_CS_book_Family Questionnaires Summary Sheet.pdf";
    link.click();
  };
  const downloadPQuestionnaire = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireSummary.pdf`;
    link.href =
      "../docs/SP_Preschool_CS_book_Family Questionnaires Summary Sheet.pdf";
    link.click();
  };

  const downloadFCCCHecklist = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolioChecklist.pdf`;
    link.href = "../docs/SP_FCC_CS_book_My CDA Professional Portfolio.pdf";
    link.click();
  };

  const downloadITCHecklist = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolioChecklist.pdf`;
    link.href = "../docs/SP_IT_CS_book_My CDA Professional Portfolio.pdf";
    link.click();
  };

  const downloadPCHecklist = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolioChecklist.pdf`;
    link.href =
      "../docs/SP_Preschool_CS_book_My CDA Professional Portfolio.pdf";
    link.click();
  };
  const downloadHVCHecklist = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolioChecklist.pdf`;
    link.href = "../docs/SP_HV_CS_book_My CDA Professional Portfolio.pdf";
    link.click();
  };
  const downloadFCCSummary = () => {
    const link = document.createElement("a");
    link.download = `SummaryofMyCDAEducation.pdf`;
    link.href = "../docs/SP_FCC_CS_book_Summary of My CDA Education.pdf";
    link.click();
  };

  const downloadITSummary = () => {
    const link = document.createElement("a");
    link.download = `SummaryofMyCDAEducation.pdf`;
    link.href = "../docs/SP_IT_CS_book_Summary of My CDA Education.pdf";
    link.click();
  };
  const downloadPSummary = () => {
    const link = document.createElement("a");
    link.download = `SummaryofMyCDAEducation.pdf`;
    link.href = "../docs/SP_Preschool_CS_book_Summary of My CDA Education.pdf";
    link.click();
  };
  const downloadHVSummary = () => {
    const link = document.createElement("a");
    link.download = `SummaryofMyCDAEducation.pdf`;
    link.href = "../docs/SP_HV_CS_book_Summary of My CDA Education.pdf";
    link.click();
  };
  const downloadParentObservationSummary = () => {
    const link = document.createElement("a");
    link.download = `ParentObservationPermissonForm.pdf`;
    link.href = "../docs/SP_HV_CS_book_HV Parent Observation Permission.pdf";
    link.click();
  };
  const downloadVerificationVisitSummary = () => {
    const link = document.createElement("a");
    link.download = `CDAVerificationVisitReflectiveDialogWorksheet.pdf`;
    link.href =
      "../docs/SP_HV_CS_book_Verification Visit Dialogue Worksheet.pdf";
    link.click();
  };
  return (
    <Grid item xs={12} mt={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "rcLibrary.main" }} />}
        >
          <Typography fontSize={18} fontWeight={700}>
            {getTranslation("Spanish")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container p={2} spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine1")}</Typography>
              <Button
                onClick={downloadFCCQuestionnaire}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine2")}</Typography>
              <Button
                onClick={downloadITQuestionnaire}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine3")}</Typography>
              <Button
                onClick={downloadPQuestionnaire}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine4")}</Typography>
              <Button
                onClick={downloadHVQuestionnaire}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine5")}</Typography>
              <Button
                onClick={downloadFCCCHecklist}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine6")}</Typography>
              <Button
                onClick={downloadITCHecklist}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine7")}</Typography>
              <Button
                onClick={downloadPCHecklist}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine8")}</Typography>
              <Button
                onClick={downloadHVCHecklist}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine9")}</Typography>
              <Button
                onClick={downloadFCCSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine10")}</Typography>
              <Button
                onClick={downloadITSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine11")}</Typography>
              <Button
                onClick={downloadPSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine12")}</Typography>
              <Button
                onClick={downloadHVSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine13")}</Typography>
              <Button
                onClick={downloadParentObservationSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>{getTranslation("EspanolLine14")}</Typography>
              <Button
                onClick={downloadVerificationVisitSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
