import { ExpandCircleDown } from "@mui/icons-material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function EportfolioOverview() {
  const { getTranslation } = useTranslation();

  return (
    <Grid item xs={12} mt={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "rcLibrary.main" }} />}
        >
          <Typography fontSize={18} fontWeight={700}>
            {getTranslation("ePortfolioOverview")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container p={2} spacing={5}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <video width="100%" height="auto" controls>
                  <source
                    src={require("../../assets/videos/Welcome.mp4")}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
