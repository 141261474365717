import { FileUpload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../app/store/configureStore";
import { openDrawer } from "../FileUpload/drawerSlice";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useCallback, useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import { AnswerswithFilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const Resource1FirstAid: React.FC<AnswerswithFilesProps> = ({
  answersData,
  RecallData,
}) => {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const { user } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const { getTranslation } = useTranslation();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCI1",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);

  function handleDeleteFile(): void {
    RecallData();
    fetchData();
  }

  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });
  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  useEffect(() => {
    fetchData();
  }, [answersData, isOpen, fetchData]);

  useEffect(() => {
    if (!isOpen) {
      RecallData();
    }
  }, [isOpen]);

  return (
    <>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          RC I-1{" "}
          {(user?.credentialTypeId === 1 ||
            user?.credentialTypeId === 2 ||
            user?.credentialTypeId === 3) &&
            getTranslation("ResourceCollectionIAid&CPR")}{" "}
          {user?.credentialTypeId === 4 && getTranslation("Training")}{" "}
        </Typography>
        {user?.credentialTypeId === 4 ? (
          <Typography variant="body1" component="div">
            {getTranslation("ResourceCollectionILine1")}
            <ul>
              <li>{getTranslation("ResourceCollectionILine2")}</li>
              <li>{getTranslation("ResourceCollectionILine3")}</li>
              <li>{getTranslation("ResourceCollectionILine4")}</li>
              <li>{getTranslation("ResourceCollectionILine5")}</li>
            </ul>
          </Typography>
        ) : (
          <Typography variant="body1" paragraph>
            {getTranslation("ResourceCollectionILine6")}
          </Typography>
        )}
        <Typography variant="body1">
          {getTranslation("ResourceCollectionILine7")}
        </Typography>
      </Grid>
      <Grid container item xs={12} padding={2}>
        <Grid item xs={12} md={6}>
          <Grid item mt={2}>
            <Typography variant="body1" fontWeight="bold">
              {getTranslation("ResourceCollectionILine8")}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<FileUpload />}
              sx={{
                textTransform: "none",
                marginTop: "10px",
              }}
              color="success"
              onClick={() => handleOpenDrawer("right", "RCI1")}
            >
              {getTranslation("UploadDocumentation")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("UploadedDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            {data.length}{" "}
            {data.length === 1
              ? getTranslation("file")
              : getTranslation("files")}{" "}
            {getTranslation("uploaded")}
          </Typography>
          {fileDataArray()}

          {/* {data.map((item) => (
            <UploadedFiles
              fileOrder={data.indexOf(item) + 1}
              key={item.id}
              fileInfo={item}
              onDeleteFile={() => handleDeleteFile()}
            />
          ))} */}
        </Grid>
      </Grid>
    </>
  );
};

export default Resource1FirstAid;
