import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Copyright from "../../app/common/Copyright";
import { Grid } from "@mui/material";
import ChangePasswordValidtionSchema from "../../app/validations/ChangePasswordValidation";
import { ChangePasswordFormInputs } from "../../app/models/ChangePassword";
import agent from "../../app/api/agent";

export default function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors, isValid },
  } = useForm<ChangePasswordFormInputs>({
    resolver: yupResolver(ChangePasswordValidtionSchema),
    mode: "onTouched",
  });

  const urlSearchParams = new URLSearchParams(location.search);
  const email = urlSearchParams.get("email");
  const token = urlSearchParams.get("token");

  function handleApiErrors(errors: any) {
    if (errors) {
      setError("confirmPassword", { message: errors.data });
    }
  }
  const onSubmit = async (data: ChangePasswordFormInputs) => {
    // Use nullish coalescing operator to provide default values for email and token
    data.emailAddress = email ?? data.emailAddress;
    data.token = token ?? data.token;

    await agent.Account.changePassword(data)
      .then(() => {
        navigate("/account/changePasswordconfirmation", {
          state: { confirm: "change" },
        });
      })
      .catch((error) => handleApiErrors(error));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                {...register("password")}
                fullWidth
                error={!!errors.password?.message}
                helperText={errors.password?.message}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                type="password"
                {...register("confirmPassword")}
                fullWidth
                error={!!errors.confirmPassword?.message}
                helperText={errors.confirmPassword?.message}
                autoComplete="confirm-password"
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
