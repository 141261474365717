import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { RegisterFormInputs } from "../../app/models/RegisterForm";
import util from "../../app/utils/utils";
import { RegistrationValidationSchema } from "../../app/validations/RegisterValidation";
import logo from "../../assets/images/CDALogo.png";
import agent from "../../app/api/agent";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@mui/material";
import Copyright from "../../app/common/Copyright";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

export default function Register() {
  const { candidateRoles, states } = util;
  // Sort the states by abbreviation
  states.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors, isValid },
  } = useForm<RegisterFormInputs>({
    resolver: yupResolver(RegistrationValidationSchema),
    mode: "onTouched",
  });

  const [emailError, setEmailError] = useState<string | null>(null);

  const checkEmailExists = async (email: string) => {
    try {
      const response = await agent.Account.checkEmailExist(email);
      return response;
    } catch (error) {
      console.error("Error checking email:", error);
      return false; // In case of an error, assume email does not exist to avoid blocking the user unnecessarily
    }
  };

  const onSubmit = async (data: RegisterFormInputs) => {
    const emailExists = await checkEmailExists(data.email);

    if (emailExists) {
      setEmailError("Email is already in use.");
      return;
    }

    setEmailError(null);

    agent.Account.register(data)
      .then(() => {
        navigate("/registersuccess");
      })
      .catch((error) => handleApiErrors(error));
  };

  function handleApiErrors(errors: any) {
    if (errors) {
      errors.forEach((error: string, index: number) => {
        if (error.includes("Password")) {
          setError("password", { message: error });
        } else if (error.includes("Email")) {
          setError("email", { message: error });
        }
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="CDA LOGO" style={{ width: "60%" }} />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                label="First Name"
                {...register("firstName")}
                fullWidth
                error={!!errors.firstName?.message}
                helperText={errors.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="family-name"
                label="Last Name"
                {...register("lastName")}
                fullWidth
                error={!!errors.lastName?.message}
                helperText={errors.lastName?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email Address"
                type="email"
                {...register("email")}
                fullWidth
                error={!!errors.email?.message || !!emailError}
                helperText={errors.email?.message || emailError}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                {...register("password")}
                fullWidth
                error={!!errors.password?.message}
                helperText={errors.password?.message}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                type="password"
                {...register("confirmPassword")}
                fullWidth
                error={!!errors.confirmPassword?.message}
                helperText={errors.confirmPassword?.message}
                autoComplete="confirm-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                {...register("phoneNumber")}
                fullWidth
                error={!!errors.phoneNumber?.message}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="CDA Council Candidate ID"
                {...register("cdaCouncilCandidateId")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Home Zip Code"
                {...register("homeZipCode")}
                fullWidth
                error={!!errors.homeZipCode?.message}
                helperText={errors.homeZipCode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.homeState?.message}>
                <InputLabel>Home State</InputLabel>
                <Select
                  label="Home State"
                  {...register("homeState")}
                  defaultValue=""
                >
                  {states.map((state) => (
                    <MenuItem
                      key={state.abbreviation}
                      value={state.abbreviation}
                    >
                      {state.abbreviation} -{" "}
                      <Typography variant="caption">{state.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
                {errors.homeState && (
                  <FormHelperText>{errors.homeState.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required error={!!errors.role?.message}>
                <InputLabel>Role</InputLabel>
                <Select label="Role" {...register("role")} defaultValue="">
                  {candidateRoles.map((role) => (
                    <MenuItem key={role.role} value={role.role}>
                      {role.role}
                    </MenuItem>
                  ))}
                </Select>
                {errors.role && (
                  <FormHelperText>{errors.role.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </LoadingButton>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/account/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
