import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { ResourceCollectionIIAnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RCIIFormValidationSchema from "../../app/validations/RCIIFormValidation";
import { useEffect, useState } from "react";
import { useTranslation } from "../../translation/translate";

const ResourceCollectionIIForm: React.FC<ResourceCollectionIIAnswersProps> = ({
  answersData,
  onAnswerChange,
  onSave,
  ResourceCollectionIICodes,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const [ageOption, setAgeOption] = useState<string>();
  const [ageOption2, setAgeOption2] = useState<string>();
  const [ageOption3, setAgeOption3] = useState<string>();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();

  const defaultValues = {
    title: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIICodes.titleCode
    )?.answer,
    titleBilingual: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIICodes.titleBilingualCode
    )?.answer,
    ageGroup: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIICodes.ageGroupCode
    )?.answer,
    ageGroupBiligual: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIICodes.ageGroupBiligualCode
    )?.answer,
    intendedGoals: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIICodes.intendedGoalsCode
    )?.answer,
    intendedGoalsBiligual: answersData.find(
      (answer) =>
        answer.systemCodeId ===
        ResourceCollectionIICodes.intendedGoalsBiligualCode
    )?.answer,
    materials: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIICodes.materialsCode
    )?.answer,
    materialsBiligual: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIICodes.materialsBiligualCode
    )?.answer,
    process: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIICodes.processCode
    )?.answer,
    processBiligual: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIICodes.processBiligualCode
    )?.answer,
    development: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIICodes.developmentCode
    )?.answer,
    developmentBiligual: answersData.find(
      (answer) =>
        answer.systemCodeId ===
        ResourceCollectionIICodes.developmentBiligualCode
    )?.answer,
  };

  const validationSchema = RCIIFormValidationSchema({
    languageSpecialization: !!user?.languageSpecialization,
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  useEffect(() => {
    if (user?.credentialTypeId === 4) {
      setAgeOption(getTranslation("FamiliesOfInfants"));
      setAgeOption2(getTranslation("FamiliesOfToddlers"));
      setAgeOption3(getTranslation("FamiliesOf3YrOlds"));
    }
    if (user?.credentialTypeId === 3) {
      setAgeOption(getTranslation("Infants"));
      setAgeOption2(getTranslation("Toddlers"));
      setAgeOption3(getTranslation("Preschoolers"));
    }
    if (user?.credentialTypeId === 2) {
      setAgeOption(getTranslation("ThreeYearOlds"));
      setAgeOption2(getTranslation("FourYearOlds"));
      setAgeOption3(getTranslation("FiveYearOlds"));
    }
    if (user?.credentialTypeId === 1) {
      setAgeOption(getTranslation("YoungInfants"));
      setAgeOption2(getTranslation("MobileInfants"));
      setAgeOption3(getTranslation("Toddlers"));
    }
  }, [user, getTranslation]);

  return (
    <Grid item>
      <Grid container spacing={3}>
        <Grid
          item
          container
          spacing={2}
          xs={12}
          md={user?.languageSpecialization ? 6 : 12}
        >
          <Grid item xs={isMobile || user?.languageSpecialization ? 12 : 6}>
            <TextField
              {...register("title")}
              label={
                user?.credentialTypeId === 4
                  ? getTranslation(
                      "RCII_Title_" +
                        ResourceCollectionIICodes.titleCode.toString()
                    )
                  : getTranslation("LearningExperienceTitle")
              }
              value={
                answersData.find(
                  (answer) =>
                    answer.systemCodeId === ResourceCollectionIICodes.titleCode
                )?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = ResourceCollectionIICodes.titleCode;
                onAnswerChange(systemCodeId, newValue);
              }}
              fullWidth
              error={!!errors.title?.message}
              helperText={errors.title?.message}
            />
          </Grid>
          <Grid item xs={isMobile || user?.languageSpecialization ? 12 : 6}>
            <FormControl fullWidth error={!!errors.ageGroup?.message}>
              <InputLabel>
                {getTranslation(
                  "RCII_AgeGroup_" +
                    ResourceCollectionIICodes.ageGroupCode.toString()
                )}
              </InputLabel>
              <Select
                {...register("ageGroup")}
                label={getTranslation(
                  "RCII_AgeGroup_" +
                    ResourceCollectionIICodes.ageGroupCode.toString()
                )}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIICodes.ageGroupCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = ResourceCollectionIICodes.ageGroupCode;

                  onAnswerChange(systemCodeId, newValue);
                }}
              >
                <MenuItem value={ageOption}>
                  {user?.credentialTypeId === 4 &&
                    getTranslation("FamiliesOfInfants")}
                  {user?.credentialTypeId === 3 && getTranslation("Infants")}
                  {user?.credentialTypeId === 2 &&
                    getTranslation("ThreeYearOlds")}
                  {user?.credentialTypeId === 1 &&
                    getTranslation("YoungInfants")}
                </MenuItem>
                <MenuItem value={ageOption2}>
                  {user?.credentialTypeId === 4 &&
                    getTranslation("FamiliesOfToddlers")}
                  {user?.credentialTypeId === 3 && getTranslation("Toddlers")}
                  {user?.credentialTypeId === 2 &&
                    getTranslation("FourYearOlds")}
                  {user?.credentialTypeId === 1 &&
                    getTranslation("MobileInfants")}
                </MenuItem>
                <MenuItem value={ageOption3}>
                  {user?.credentialTypeId === 4 &&
                    getTranslation("FamiliesOf3YrOlds")}
                  {user?.credentialTypeId === 3 &&
                    getTranslation("Preschoolers")}
                  {user?.credentialTypeId === 2 &&
                    getTranslation("FiveYearOlds")}
                  {user?.credentialTypeId === 1 && getTranslation("Toddlers")}
                </MenuItem>
                {user?.credentialTypeId === 4 && (
                  <MenuItem value={getTranslation("FamiliesOf4And5YrOlds")}>
                    {getTranslation("FamiliesOf4And5YrOlds")}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("intendedGoals")}
              label={
                user?.credentialTypeId === 4
                  ? getTranslation("IntendedGoals")
                  : getTranslation(
                      "RCII_IntendedGoals_" +
                        ResourceCollectionIICodes.intendedGoalsCode.toString()
                    )
              }
              value={
                answersData.find(
                  (answer) =>
                    answer.systemCodeId ===
                    ResourceCollectionIICodes.intendedGoalsCode
                )?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId =
                  ResourceCollectionIICodes.intendedGoalsCode;
                newValue.length <= 1000 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              multiline
              rows={4}
              fullWidth
              error={!!errors.intendedGoals?.message}
              helperText={errors.intendedGoals?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.intendedGoals?.length}/1000
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("materials")}
              label={
                user?.credentialTypeId === 4
                  ? getTranslation("Materials") + ":"
                  : getTranslation(
                      "RCII_Materials–_" +
                        ResourceCollectionIICodes.materialsCode.toString()
                    ) + ":"
              }
              value={
                answersData.find(
                  (answer) =>
                    answer.systemCodeId ===
                    ResourceCollectionIICodes.materialsCode
                )?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = ResourceCollectionIICodes.materialsCode;
                newValue.length <= 1000 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              multiline
              rows={4}
              fullWidth
              error={!!errors.materials?.message}
              helperText={errors.materials?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.materials?.length}/1000
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("process")}
              label={
                user?.credentialTypeId === 4
                  ? getTranslation("ProcessTeachingStrategies") + ":"
                  : getTranslation(
                      "RCII_ProcessTeachingstrategies_" +
                        ResourceCollectionIICodes.processCode.toString()
                    ) + ":"
              }
              value={
                answersData.find(
                  (answer) =>
                    answer.systemCodeId ===
                    ResourceCollectionIICodes.processCode
                )?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = ResourceCollectionIICodes.processCode;
                newValue.length <= 1000 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              multiline
              rows={4}
              fullWidth
              error={!!errors.process?.message}
              helperText={errors.process?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.process?.length}/1000
            </Typography>
          </Grid>
          {user?.credentialTypeId !== 4 && (
            <Grid item xs={12}>
              <TextField
                {...register("development")}
                label={getTranslation(
                  "RCII_DevelopmentallyAppropriate_" +
                    ResourceCollectionIICodes.developmentCode.toString()
                )}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIICodes.developmentCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId =
                    ResourceCollectionIICodes.developmentCode;
                  newValue.length <= 1000 &&
                    onAnswerChange(systemCodeId, newValue);
                }}
                multiline
                rows={4}
                fullWidth
                error={!!errors.development?.message}
                helperText={errors.development?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.development?.length}/1000
              </Typography>
            </Grid>
          )}
        </Grid>

        {user?.languageSpecialization && (
          <Grid item container spacing={2} xs={12} md={6}>
            <Grid item xs={12}>
              <TextField
                {...register("titleBilingual")}
                label={getTranslation(
                  "RCII_Titlein_" + ResourceCollectionIICodes.titleBilingualCode
                )}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIICodes.titleBilingualCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId =
                    ResourceCollectionIICodes.titleBilingualCode;
                  onAnswerChange(systemCodeId, newValue);
                }}
                fullWidth
                error={!!errors.titleBilingual?.message}
                helperText={errors.titleBilingual?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("ageGroupBiligual")}
                label={getTranslation(
                  "RCII_AgeGroup_" +
                    ResourceCollectionIICodes.ageGroupBiligualCode
                )}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIICodes.ageGroupBiligualCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId =
                    ResourceCollectionIICodes.ageGroupBiligualCode;
                  onAnswerChange(systemCodeId, newValue);
                }}
                fullWidth
                error={!!errors.ageGroupBiligual?.message}
                helperText={errors.ageGroupBiligual?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("intendedGoalsBiligual")}
                label={
                  user?.credentialTypeId === 4
                    ? getTranslation("RCIILearningExperienceAccordion2Line9")
                    : getTranslation(
                        "RCII_IntendedGoals_" +
                          ResourceCollectionIICodes.intendedGoalsBiligualCode
                      )
                }
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIICodes.intendedGoalsBiligualCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId =
                    ResourceCollectionIICodes.intendedGoalsBiligualCode;
                  newValue.length <= 1000 &&
                    onAnswerChange(systemCodeId, newValue);
                }}
                multiline
                rows={4}
                fullWidth
                error={!!errors.intendedGoalsBiligual?.message}
                helperText={errors.intendedGoalsBiligual?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.intendedGoalsBiligual?.length}/1000
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("materialsBiligual")}
                label={
                  user?.credentialTypeId === 4
                    ? getTranslation("Materials") + ":"
                    : getTranslation(
                        "RCII_Materials–_" +
                          ResourceCollectionIICodes.materialsBiligualCode
                      )
                }
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIICodes.materialsBiligualCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId =
                    ResourceCollectionIICodes.materialsBiligualCode;
                  newValue.length <= 1000 &&
                    onAnswerChange(systemCodeId, newValue);
                }}
                multiline
                rows={4}
                fullWidth
                error={!!errors.materialsBiligual?.message}
                helperText={errors.materialsBiligual?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.materialsBiligual?.length}/1000
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("processBiligual")}
                label={
                  user?.credentialTypeId === 4
                    ? getTranslation("ProcessTeachingStrategiesSecondLanguage")
                    : getTranslation(
                        "RCII_ProcessTeachingstrategies_" +
                          ResourceCollectionIICodes.processBiligualCode
                      )
                }
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIICodes.processBiligualCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId =
                    ResourceCollectionIICodes.processBiligualCode;
                  newValue.length <= 1000 &&
                    onAnswerChange(systemCodeId, newValue);
                }}
                multiline
                rows={4}
                fullWidth
                error={!!errors.processBiligual?.message}
                helperText={errors.processBiligual?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.processBiligual?.length}/1000
              </Typography>
            </Grid>
            {user?.credentialTypeId !== 4 && (
              <Grid item xs={12}>
                <TextField
                  {...register("developmentBiligual")}
                  label={getTranslation(
                    "RCII_DevelopmentallyAppropriate_" +
                      ResourceCollectionIICodes.developmentBiligualCode
                  )}
                  value={
                    answersData.find(
                      (answer) =>
                        answer.systemCodeId ===
                        ResourceCollectionIICodes.developmentBiligualCode
                    )?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId =
                      ResourceCollectionIICodes.developmentBiligualCode;
                    newValue.length <= 1000 &&
                      onAnswerChange(systemCodeId, newValue);
                  }}
                  multiline
                  rows={4}
                  fullWidth
                  error={!!errors.developmentBiligual?.message}
                  helperText={errors.developmentBiligual?.message}
                />
                <Typography fontSize={13}>
                  {defaultValues.developmentBiligual?.length}/1000
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} mr={2}>
          <Button
            onClick={onSave}
            color="info"
            variant="outlined"
            sx={{ marginRight: 3, marginBottom: isMobile ? 2 : 0 }}
          >
            {getTranslation("Save")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ResourceCollectionIIForm;
