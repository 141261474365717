import { Grid, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useForm } from "react-hook-form";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const FamilyQuestionnaire: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const defaultValues = {
    reflections: answersData.find((answer) => answer.systemCodeId === 187)
      ?.answer,
    feedback: answersData.find((answer) => answer.systemCodeId === 188)?.answer,
    explain: answersData.find((answer) => answer.systemCodeId === 189)?.answer,
  };
  const schema = yup.object().shape({
    reflections: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    feedback: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    explain: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid container p={2} m={1} bgcolor="#F3EBFC">
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700} mb={3}>
            {getTranslation("SIVFamilyQuestionnaireLine1")}
          </Typography>
          <Typography>
            {user?.credentialTypeId === 4
              ? getTranslation("SIVFamilyQuestionnaireLine2")
              : getTranslation("SIVHappeningHomeLine3")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography mb={2}>
            {getTranslation("SIVFamilyQuestionnaireLine7A")}
          </Typography>
          <TextField
            sx={{ backgroundColor: "white" }}
            label={getTranslation("SIVFamilyQuestionnaireLine7")}
            fullWidth
            multiline
            rows={2}
            {...register("explain")}
            name="explain"
            value={
              answersData.find((answer) => answer.systemCodeId === 189)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 189;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.explain?.message}
            helperText={errors.explain?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.explain?.length}/500
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography mb={2}>
            {getTranslation("SIVFamilyQuestionnaireLine3")}
          </Typography>
          <TextField
            sx={{ backgroundColor: "white" }}
            label={getTranslation("SIVFamilyQuestionnaireLine4")}
            fullWidth
            multiline
            rows={2}
            {...register("reflections")}
            name="reflections"
            value={
              answersData.find((answer) => answer.systemCodeId === 187)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 187;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.reflections?.message}
            helperText={errors.reflections?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.reflections?.length}/500
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography mb={2}>
            {getTranslation("SIVFamilyQuestionnaireLine5")}
          </Typography>
          <TextField
            sx={{ backgroundColor: "white" }}
            label={getTranslation("SIVFamilyQuestionnaireLine6")}
            fullWidth
            multiline
            rows={2}
            {...register("feedback")}
            name="feedback"
            value={
              answersData.find((answer) => answer.systemCodeId === 188)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 188;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.feedback?.message}
            helperText={errors.feedback?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.feedback?.length}/500
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FamilyQuestionnaire;
