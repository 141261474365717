import { Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { AnswersProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const ImportantAspects: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const defaultValues = {
    importantAspect: answersData.find((answer) => answer.systemCodeId === 261)
      ?.answer,
  };
  const { getTranslation } = useTranslation();
  useForm({
    defaultValues,
  });

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Typography fontWeight={700} mb={2}>
          {getTranslation("ImportantAspectsLine1")}
        </Typography>
        <Typography>{getTranslation("ImportantAspectsLine2")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder={getTranslation("ImportantAspectsLine3")}
          name="importantAspect"
          inputProps={{ maxLength: 500 }}
          fullWidth
          multiline
          rows={2}
          value={
            answersData.find((answer) => answer.systemCodeId === 261)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 261;
            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
        />
        <Typography fontSize={13}>
          {defaultValues.importantAspect?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ImportantAspects;
