import { Grid } from "@mui/material";
import Resource1Uploads from "./Resource1Uploads";
import ResourceIVUploads from "./ResourceIVUploads";
import ResourceVUploads from "./ResourceVUploads";
import FamilyQuestionnaireUploads from "./FamilyQuestionnaireUploads";
import AdditionalForms from "./AdditionalForms";
import MyUploadStatus from "./MyUploadStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import CdaEduUpload from "./CdaEduUpload";
import { useTranslation } from "../../translation/translate";
import ResourceVIUploads from "./ResourceVIUploads";

export default function MyUploads() {
  const { getTranslation } = useTranslation();
  const summaryCompleteProps: SummaryCompleteProps = {
    title: getTranslation("MyUploads"),
    percent: "10%",
    header: "",
    description: getTranslation("MyUploadsDescription"),
  };

  return (
    <Grid container p={2} m={1}>
      <MyUploadStatus {...summaryCompleteProps} />
      <CdaEduUpload />
      <Resource1Uploads />
      <ResourceIVUploads />
      <ResourceVUploads />
      <ResourceVIUploads />
      <FamilyQuestionnaireUploads />
      <AdditionalForms />
    </Grid>
  );
}
