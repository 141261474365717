import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAnyOf } from "@reduxjs/toolkit";
import { User } from "../../app/models/user";
import { FieldValues } from "react-hook-form";
import agent from "../../app/api/agent";
import { router } from "../../app/router/route";

interface AccountState {
  user: User | null;
}
interface UserSSO {
  id: string;
  username: string;
  token: string; // Raw token
  role: string;
  welcomeComplete: boolean;
  email: string;
  firstName: string;
  lastName: string;
  credentialName?: string | null; // Nullable
  credentialTypeId?: number | null; // Nullable
  cdaCouncilCandidateId?: number | null; // Nullable
  languageSpecialization?: string | null; // Nullable
  portfolioId?: number | null; // Nullable
  homeState?: string | null; // Nullable
  phoneNumber?: string | null; // Nullable
  homeZipCode?: string | null; // Nullable
  isActive: boolean;
  isSpanish?: boolean;
}

const initialState: AccountState = {
  user: null,
};

// Sign in user via traditional login
export const signInUser = createAsyncThunk<User, FieldValues>(
  "account/signInUser",
  async (data, thunkAPI) => {
    try {
      const user = await agent.Account.login(data);
      localStorage.setItem("user", JSON.stringify(user));
      let claims = JSON.parse(window.atob(user.token.split(".")[1]));
      let role = claims["role"];
      const userWithRole = { ...user, role: role };
      return userWithRole;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);

// Sign in user via SSO
export const signInUserSSO = createAsyncThunk<UserSSO, FieldValues>(
  "account/signInUserSSO",
  async (data, thunkAPI) => {
    try {
      const user = data as UserSSO;

      console.log("Received data:", data);

      // Store the user in localStorage for persistence
      localStorage.setItem("user", JSON.stringify(user));

      // Ensure token exists before attempting to parse it
      if (!user.token) {
        throw new Error("Token is missing from the user object");
      }

      // Parse the token to extract claims (e.g., role)
      let claims;
      try {
        claims = JSON.parse(window.atob(user.token.split(".")[1]));
      } catch (tokenParseError) {
        console.error("Error parsing JWT token:", tokenParseError);
        throw new Error("Invalid token format");
      }

      console.log("user.role:", user.role);
      let role = user.role;

      // Add the role to the user object
      const userWithRole: UserSSO = { ...user, role: role };

      // Return the user with role
      return userWithRole;
    } catch (error: any) {
      // Log the error for better debugging
      console.error("Error during signInUserSSO:", error.message || error);

      // Handle errors and reject the thunk
      return thunkAPI.rejectWithValue({
        error: error?.response?.data || "An unknown error occurred",
      });
    }
  }
);

// Fetch current user
export const fetchCurrentUser = createAsyncThunk<User>(
  "account/fetchCurrentUser",
  async (_, thunkAPI) => {

    thunkAPI.dispatch(setUser(JSON.parse(localStorage.getItem("user")!)));
    try {
      const user = await agent.Account.currentUser();

    
      user.role = localStorage.getItem("role");

      localStorage.setItem("user", JSON.stringify(user));
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
  {
    condition: () => {
      if (!localStorage.getItem("user")) return false;
    },
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    signOut: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("translations");
      router.navigate("/");
    },
    setUser: (state, action) => {
      let claims = JSON.parse(window.atob(action.payload.token.split(".")[1]));
      let role = claims["role"];
      state.user = { ...action.payload, role: role };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.rejected, (state) => {
      state.user = null;
      localStorage.removeItem("user");
      router.navigate("/");
    });
    builder.addMatcher(
      isAnyOf(signInUser.fulfilled, fetchCurrentUser.fulfilled),
      (state, action) => {
        let claims = JSON.parse(
          window.atob(action.payload.token.split(".")[1])
        );
        let role = claims["role"];
        state.user = { ...action.payload, role: role };
      }
    );
    builder.addMatcher(isAnyOf(signInUser.rejected), (state, action) => {
      throw action.payload;
    });
  },
});

export const { signOut, setUser } = accountSlice.actions;

export default accountSlice.reducer;
