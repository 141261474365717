import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import ChildhoodAssociationEntry from "./ChildhoodAssociationEntry";
import { useCallback, useEffect, useState } from "react";
import { RCVIUploadForm } from "../../app/models/RCVIUploadForm";
import { SubmitHandler } from "react-hook-form/dist/types";
import { ExpandCircleDown } from "@mui/icons-material";
import { useAppSelector } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import { Answers, FilesProps } from "../../app/models/Answers";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useTranslation } from "../../translation/translate";

const ChildhoodAssociation: React.FC<FilesProps> = ({ RecallData }) => {
  const { user } = useAppSelector((state) => state.account);
  const [portfolioId, setPortfoliId] = useState<number>(0);
  const [newEntry, setNewEntry] = useState<RCVIUploadForm[]>([]);
  const [data, setData] = useState<FileInfo[]>([]);
  const [descriptionValue, setDescriptionValue] = useState<string | null>("");
  const [associationValue, setAssociationValue] = useState<string>("");
  const [websiteValue, setWebsiteValue] = useState<string>("");
  const [idValue, setIdValue] = useState<string>();
  const [showDiv, setShowDiv] = useState(false);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const { getTranslation } = useTranslation();

  const [open, setOpen] = useState({ show: false, id: "" });

  const handleClickOpen = (id: any) => {
    setOpen({ show: true, id: id });
  };

  const handleClose = () => {
    setOpen({ show: false, id: "" });
  };

  const fetchData = useCallback(async () => {
    const data = await agent.FileUpload.GetFileInfo(
      "RCVI2",
      user?.portfolioId ?? 0
    );
    setData(data);
    if (user && user.portfolioId) {
      const data =
        await agent.EarlyChildhoodAssociations.GetEarlyChildhoodAssociations(
          user.portfolioId
        );
      setPortfoliId(user.portfolioId);
      setNewEntry(data);
    }
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "RCVI"
        );
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user, setPortfoliId, setNewEntry, setAnswers, setIsLoading]);

  useEffect(() => {
    fetchData();
  }, [user, fetchData]);

  const handleAnswerChange = async (systemCodeId: number, newValue: string) => {
    // Create a copy of the answers array to avoid mutating state directly
    const updatedAnswers = [...answers];

    // Find the index of the answer with the given systemCodeId
    const answerIndex = updatedAnswers.findIndex(
      (answer) => answer.systemCodeId === systemCodeId
    );

    if (answerIndex !== -1) {
      // Update the value of the answer at the specified index
      updatedAnswers[answerIndex] = {
        ...updatedAnswers[answerIndex],
        answer: newValue,
      };
    }

    // Filter out answers with empty values
    const filteredAnswers = updatedAnswers.filter(
      (answer) => answer.answer !== ""
    );

    // Update the state with the new answers array
    setAnswers(filteredAnswers);

    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
  };

  const handleToggle = () => {
    setShowDiv((prev) => !prev);
  };

  const onSubmit: SubmitHandler<RCVIUploadForm> = async (data) => {
    const newData = { ...data, portfolioId: portfolioId };
    if (user && user.portfolioId) {
      await agent.EarlyChildhoodAssociations.PostEarlyChildhoodAssociations(
        user?.portfolioId,
        newData
      );
    }
    setNewEntry([...newEntry, data]);
    handleToggle();
    fetchData();
    RecallData();
  };

  const counter = (id: string) => {
    const arr = newEntry
      .map((item: any) => {
        return item.id;
      })
      .indexOf(id);
    return arr + 1;
  };

  const handleRemove = (id: string, counterId: number) => {
    const newList = newEntry.filter((item: any) => item.id !== id);
    setNewEntry(newList);
    agent.EarlyChildhoodAssociations.DeleteEarlyChildhoodAssociations(id);
    handleClose();

    const filteredData = data.filter(
      (item: { certificateType: string | string[] }) =>
        item.certificateType.includes(counterId.toString())
    );
    for (const item of filteredData) {
      agent.FileUpload.DeleteFile(item.id);
    }
    RecallData();
  };

  function handleDeleteFile(): void {
    fetchData();
    RecallData();
  }

  if (isLoading) {
    return null;
  }

  const handleEdit = (id: any) => {
    setIsEdit(true);
    const updateEntry = newEntry.find((item) => item.id === id);
    if (updateEntry) {
      setIdValue(updateEntry.id);
      setDescriptionValue(updateEntry.description);
      setAssociationValue(updateEntry.association);
      setWebsiteValue(updateEntry.website);
    }
    const newList = newEntry.filter((item: any) => item.id !== id);
    setNewEntry(newList);

    setShowDiv(true);
    RecallData();
  };
  const resetInput = () => {
    setAssociationValue("");
    setDescriptionValue("");
    setWebsiteValue("");
  };

  return (
    <Grid container p={2} m={1} bgcolor={"compVI.light"}>
      <Grid item>
        <Typography fontSize={18} fontWeight={700} mb={1}>
          {getTranslation("RCVIChildhoodAssociationsLine1")}
        </Typography>
        <Typography mb={3}>
          {getTranslation("RCVIChildhoodAssociationsLine2")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={18} fontWeight={700}>
          {getTranslation("RCVIChildhoodAssociationsLine3")}
        </Typography>
      </Grid>
      {user?.credentialTypeId !== 4 && (
        <Grid item xs={12} mb={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandCircleDown sx={{ color: "compVI.main" }} />}
            >
              <Typography fontSize={18} fontWeight={700}>
                {getTranslation("RCVIChildhoodAssociationsLine4")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container p={2}>
                <Grid item xs={12}>
                  <Typography>
                    {getTranslation("RCVIChildhoodAssociationsLine5")}{" "}
                    <Button
                      href={getTranslation("EarlyChildhooSampleWebsite")}
                      target="_blank"
                    >
                      {getTranslation("EarlyChildhooSampleWebsite")}
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    {getTranslation("RCVIChildhoodAssociationsLine6")}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {newEntry.map((entry: any) => (
        <Grid item key={entry.id} xs={12} mb={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandCircleDown sx={{ color: "compVI.main" }} />}
            >
              <Typography mr={2} color="primary" fontSize={18} fontWeight={700}>
                {counter(entry.id)}
              </Typography>
              <Typography fontWeight={700}>{entry.association}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} p={2}>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>
                    {getTranslation("RCVIChildhoodAssociationsLine7")}
                  </Typography>
                  <Typography>{entry.association}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>
                    {getTranslation("Website")}
                  </Typography>
                  <Typography
                    textOverflow={"ellipsis"}
                    maxWidth={500}
                    overflow={"hidden"}
                  >
                    {entry.website}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={600}>
                    {getTranslation("RCVIChildhoodAssociationsLine8")}
                  </Typography>
                  <Typography>{entry.description}</Typography>
                  <Grid item xs={6}>
                    {data
                      .filter((item: { certificateType: string | string[] }) =>
                        item.certificateType.includes(
                          counter(entry.id).toString()
                        )
                      )
                      .map((filteredItem) => (
                        <UploadedFiles
                          fileOrder={data.indexOf(filteredItem) + 1}
                          key={filteredItem.id}
                          fileInfo={filteredItem}
                          onDeleteFile={() => handleDeleteFile()}
                        />
                      ))}
                  </Grid>
                </Grid>
                <Grid item display="flex" justifyContent="flex-end" xs={12}>
                  <Button
                    variant="outlined"
                    color="compVI"
                    onClick={() => handleEdit(entry.id)}
                  >
                    {getTranslation("RCVIChildhoodAssociationsLine9")}
                  </Button>
                  <Button
                    key={entry.id}
                    variant="outlined"
                    color="compVI"
                    onClick={() => handleClickOpen(entry.id)}
                  >
                    {getTranslation("RCVIChildhoodAssociationsLine10")}
                  </Button>
                  <Dialog
                    open={open.show}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {getTranslation("RCVIChildhoodAssociationsLine11")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {getTranslation("RCVIChildhoodAssociationsLine12")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => handleRemove(open.id, counter(entry.id))}
                      >
                        {getTranslation("Delete")}
                      </Button>
                      <Button onClick={handleClose} autoFocus>
                        {getTranslation("Cancel")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}

      {showDiv && (
        <ChildhoodAssociationEntry
          toggle={handleToggle}
          onsubmit={onSubmit}
          idValue={idValue}
          associationCount={newEntry.length}
          answersData={answers}
          descriptionValue={descriptionValue}
          setDescriptionValue={setDescriptionValue}
          associationValue={associationValue}
          setAssociationValue={setAssociationValue}
          websiteValue={websiteValue}
          setWebsiteValue={setWebsiteValue}
          isEdit={isEdit}
        />
      )}

      <Grid item xs={12} display="flex" justifyContent="center">
        {newEntry.length < 3 && (
          <Button
            variant="text"
            color="compVI"
            onClick={() => {
              resetInput();
              setIsEdit(false);
              handleToggle();
            }}
          >
            {getTranslation("RCVIChildhoodAssociationsLine13")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ChildhoodAssociation;
