import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import agent from "../../app/api/agent";
import { useAppSelector } from "../../app/store/configureStore";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "../../translation/translate";

export default function Review() {
  const { user } = useAppSelector((state) => state.account);
  const [loadingFinalPDF, setLoadingFinalPDF] = useState(false);
  const { getTranslation } = useTranslation();

  const handlePDF = async () => {
    setLoadingFinalPDF(true);
    try {
      // Generate the final PDF
      await agent.PDFViewer.GenerateFinalPDF(user?.portfolioId ?? 0, "Preview");

      // Get file info
      const data = await agent.FileUpload.GetFileInfo(
        "PreviewPDF",
        user?.portfolioId ?? 0
      );

      // Get the file
      const response = await agent.FileUpload.GetFiles(data[0].id);

      // Create a blob from the file response
      const blob = new Blob([response], { type: "application/pdf" });

      // Create a URL for the blob
      const imageUrl = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(imageUrl, "_blank");
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoadingFinalPDF(false);
    }
  };

  return (
    <Grid item xs={12} mt={2} px={3} pt={3}>
      <Typography variant="body1" fontWeight="bold">
        2. {getTranslation("PuttingAllTogetherHeaderReviewLine1")}
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            {getTranslation("PuttingAllTogetherHeaderReviewLine2")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {getTranslation("PuttingAllTogetherHeaderReviewLine3")}
          </Typography>
        </li>
        <LoadingButton
          loading={loadingFinalPDF}
          onClick={handlePDF}
          variant="outlined"
          color="secondary"
          sx={{
            marginBottom: "15px",
            textTransform: "initial",
          }}
        >
          {getTranslation("PuttingAllTogetherHeaderReviewLine4")}
        </LoadingButton>
      </ul>
    </Grid>
  );
}
