import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import teacher from "../../assets/images/teacher-and-web-page.png";
import SubHeaderTwo from "../../app/layout/SubHeaderTwo";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { useTranslation } from "../../translation/translate";
import SelectLanguageModal from "./SelectLanguageModal";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { fetchCurrentUser } from "../account/accountSlice";

const Welcome = () => {
  const navigate = useNavigate();
  console.log("Welcome component rendered");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { user } = useAppSelector((state) => state.account);
  console.log("user:", user);
  
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    user?.isSpanish ? "true" : "false"
  ); // Set based on user's isSpanish status
  
  const { getTranslation } = useTranslation();
  console.log("selectedLanguage:", selectedLanguage);
  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    console.log("Fetching data");
    if (user?.welcomeComplete) {
      console.log("User is already complete");
      navigate("/myprogresssummary");
    }
  }, [navigate, user]);

  useEffect(() => {
    if (isFirstLoad) {
      fetchData();
      setIsFirstLoad(false); // Set to false after first fetch
    }
  }, [fetchData, isFirstLoad]);

  const handleLanguageChange = async (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value !== selectedLanguage) {
      setSelectedLanguage(value);
      
      const data = await agent.Account.currentUser();
      data.isSpanish = value === "true";
      await agent.Account.updateAccount(data);
      console.log("UpdateAccount:", data);
      
      await dispatch(fetchCurrentUser());
      fetchData();
    }
  };

  const handleSave = async () => {
    setIsFirstLoad(false); // Close modal after saving
  };

  const handleClick = () => {
    console.log("Button clicked: Navigate to /welcome/cdabook");
    navigate("/welcome/cdabook");
  };

  return (
    <Box>
      {isFirstLoad && (
        <SelectLanguageModal
          isOpen={isFirstLoad}
          onClose={() => setIsFirstLoad(false)}
          selectedLanguage={selectedLanguage} // Pass down state and handlers
          handleLanguageChange={handleLanguageChange}
          handleSave={handleSave}
        />
      )}
      {!isFirstLoad && (
        <>
          <SubHeaderTwo />
          <Grid container>
            <Grid item xs={12} md={6}>
              <img
                src={teacher}
                alt="teacher and web page"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ pl: 4 }}>
              <Typography sx={{ ml: 2, fontWeight: "bold", lineHeight: 0.8 }}>
                {getTranslation("WelcomeLine1")}
              </Typography>
              <List sx={{ mb: 2 }}>
                <ListItem>
                  <Typography>• {getTranslation("WelcomeLine2")}</Typography>
                </ListItem>
                <ListItem>
                  <Typography>• {getTranslation("WelcomeLine3")}</Typography>
                </ListItem>
                <ListItem>
                  <Typography>• {getTranslation("WelcomeLine4")}</Typography>
                </ListItem>
                <ListItem>
                  <Typography>• {getTranslation("WelcomeLine5")}</Typography>
                </ListItem>
                <ListItem>
                  <Typography>• {getTranslation("WelcomeLine6")}</Typography>
                </ListItem>
              </List>
              <Button
                onClick={handleClick}
                variant="contained"
                sx={{ textTransform: "none", ml: 2, mb: 3 }}
              >
                {getTranslation("WelcomeLine7")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Welcome;