import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useTranslation } from "../../translation/translate";

export default function BooksArr(props: any) {
  const { bookData, counter, handleRemove, handleEdit } = props;
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  return (
    <>
      {bookData.map((books: any) => (
        <Grid item mb={2} p={2} key={books.id}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandCircleDown sx={{ color: "comp3.main" }} />}
            >
              <Typography fontSize={20} fontWeight={700} mr={2} color="primary">
                {counter(books.id)}
              </Typography>
              <Typography mt={0.3} fontWeight={700}>
                {books.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} container padding={1} spacing={2}>
                  <Grid item xs={6}>
                    <Typography fontWeight={700}>
                      {getTranslation("RCII_Title_63")}
                    </Typography>
                    <Typography>{books.title}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={700}>
                      {getTranslation("ResourceIIIBookAuthor")}
                    </Typography>
                    <Typography>{books.author}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={700}>
                      {getTranslation("ResourceIIIBookPublisher")}
                    </Typography>
                    <Typography>{books.publisher}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={700}>
                      {getTranslation("ResourceIIIBookCopyright")}
                    </Typography>
                    <Typography>{books.copyright}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={700}>
                      {getTranslation("ResourceIIIBookDescription")}
                    </Typography>
                    <Typography>{books.summary}</Typography>
                  </Grid>
                  {user?.languageSpecialization && (
                    <Grid item xs={12}>
                      <Typography fontWeight={700}>
                        
                        {getTranslation(
                          "ResourceIIIBookDescription2ndLanguage"
                        )}
                      </Typography>
                      <Typography> {books.summary2ndLanguage}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Grid item mr={1}>
                  <Button
                    onClick={() => handleRemove(books.id)}
                    color="comp3"
                    variant="outlined"
                  >
                    {getTranslation("Delete")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleEdit(books.id)}
                    color="comp3"
                    variant="contained"
                  >
                    {getTranslation("Edit")}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </>
  );
}
