import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useTranslation } from "../../translation/translate";

export default function Learning() {
  const { user } = useAppSelector((state) => state.account);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getTranslation } = useTranslation();

  return (
    <Grid item xs={12} px={3} pt={3}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown className="blue-expand-icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("RCIILearningExperienceAccordion1")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} p={1}>
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography fontWeight={700}>
                {user?.credentialTypeId === 4
                  ? `*${getTranslation("LearningExperienceTitle")}`
                  : getTranslation("LearningExperienceTitle")}
              </Typography>
              <Typography>
                {user?.credentialTypeId === 4
                  ? getTranslation("DrawingActivity")
                  : getTranslation("WaterTableActivity")}
              </Typography>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography fontWeight={700}>
                *{getTranslation("LearningExperienceAgeGroup")}
              </Typography>
              <Typography>
                *
                {user?.credentialTypeId === 4
                  ? `${getTranslation("LearningExperienceAgeGroupText")}`
                  : `${user?.credentialName}`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={700}>
                {user?.credentialTypeId === 4
                  ? getTranslation("IntendedGoals")
                  : getTranslation("IntendedGoalsQuestion1")}
              </Typography>

              <Typography>
                {user?.credentialTypeId === 4 ? (
                  getTranslation("ExpandChildVocabulary")
                ) : (
                  <ul>
                    <Typography>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail1"
                      )}
                      :
                    </Typography>
                    <li>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail2"
                      )}
                    </li>
                    <li>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail3"
                      )}
                    </li>
                  </ul>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={700}>
                {user?.credentialTypeId === 4
                  ? getTranslation("RCIILearningExperienceAccordion1Detail4")
                  : getTranslation("RCIILearningExperienceAccordion1Detail5")}
              </Typography>
              <Typography>
                {user?.credentialTypeId === 4
                  ? getTranslation("RCIILearningExperienceAccordion1Detail6")
                  : getTranslation("RCIILearningExperienceAccordion1Detail7")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={700}>
                {user?.credentialTypeId === 4
                  ? getTranslation("RCIILearningExperienceAccordion1Detail8")
                  : getTranslation("RCIILearningExperienceAccordion1Detail9")}
              </Typography>
              <Typography component="div">
                {user?.credentialTypeId === 4 ? (
                  <ul>
                    <li>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail10"
                      )}
                    </li>
                    <li>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail11"
                      )}
                    </li>
                    <li>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail12"
                      )}
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail13"
                      )}
                    </li>
                    <li>
                      {getTranslation(
                        "RCIILearningExperienceAccordion1Detail14"
                      )}
                    </li>
                  </ul>
                )}
              </Typography>
            </Grid>
            {user?.credentialTypeId !== 4 && (
              <Grid item xs={12}>
                <Typography fontWeight={700}>
                  {getTranslation("RCIILearningExperienceAccordion1Detail15")}
                </Typography>
                <Typography>
                  {getTranslation("RCIILearningExperienceAccordion1Detail16")}
                </Typography>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
