import { useForm } from "react-hook-form";
import { useAppSelector } from "../../app/store/configureStore";
import { BookForm } from "../../app/models/BookForm";
import {
  Box,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import BookValidationSchema from "../../app/validations/BookValidation";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "../../translation/translate";

export default function NewBook({
  isEdit,
  showDiv,
  onSubmit,
  editID,
  editTitle,
  setEditTitle,
  editAuthor,
  setEditAuthor,
  editCopyright,
  setEditCopyright,
  editPublisher,
  setEditPublisher,
  editSummary,
  setEditSummary,
  editSummaryBL,
  setEditSummaryBL,
}: any) {
  const { user } = useAppSelector((state) => state.account);
  const validationSchema = BookValidationSchema({
    languageSpecialization: !!user?.languageSpecialization,
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const textStyle1 = { marginRight: 3, mb: isMobile ? 2 : 0 };
  const gridStyle1 = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    mb: 2,
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm<BookForm>({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });
  const { getTranslation } = useTranslation();

  const handleCopyrightInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
    if (numericInput.length <= 4) {
      setEditCopyright(event.target.value);
    }
  };

  return (
    <Box p={2} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container display={showDiv ? "flex" : "none"} spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={12} sx={gridStyle1}>
            {isEdit && (
              <TextField
                style={{ display: "none" }}
                value={editID}
                {...register("id")}
              />
            )}
            <TextField
              {...register("portfolioId", { shouldUnregister: false })}
              defaultValue={user?.portfolioId}
              style={{ display: "none" }}
            />
            <TextField
              {...register("title", { shouldUnregister: false })}
              label={getTranslation("ResourceIIIBookTitle")}
              sx={textStyle1}
              value={editTitle}
              onChange={(e) => {
                const newValue = e.target.value;
                setEditTitle(newValue);
              }}
              fullWidth
              error={!!errors.title?.message}
              helperText={errors.title?.message}
            />

            <TextField
              {...register("author", { shouldUnregister: false })}
              label={getTranslation("ResourceIIIBookAuthor")}
              value={editAuthor}
              onChange={(e) => {
                const newValue = e.target.value;
                setEditAuthor(newValue);
              }}
              fullWidth
              error={!!errors.author?.message}
              helperText={errors.author?.message}
            />
          </Grid>
          <Grid item xs={12} sx={gridStyle1}>
            <TextField
              {...register("publisher", { shouldUnregister: false })}
              sx={textStyle1}
              label={getTranslation("ResourceIIIBookPublisher")}
              value={editPublisher}
              onChange={(e) => {
                const newValue = e.target.value;
                setEditPublisher(newValue);
              }}
              fullWidth
              error={!!errors.publisher?.message}
              helperText={errors.publisher?.message}
            />

            <TextField
              {...register("copyright", { shouldUnregister: false })}
              label={getTranslation("ResourceIIIBookCopyright")}
              value={editCopyright}
              placeholder="ex. 1999"
              fullWidth
              error={!!errors.copyright?.message}
              helperText={errors.copyright?.message}
              onChange={handleCopyrightInput}
            />
          </Grid>
          <Grid item xs={12} sx={gridStyle1}>
            <Grid
              item
              xs={12}
              md={user?.languageSpecialization ? 6 : 12}
              mr={isMobile ? 0 : 3}
            >
              <TextField
                {...register("summary", { shouldUnregister: false })}
                label={getTranslation("ResourceIIIBookDescription")}
                value={editSummary}
                onChange={(e) => {
                  const newValue = e.target.value;
                  newValue.length <= 1000 && setEditSummary(newValue);
                }}
                fullWidth
                error={!!errors.summary?.message}
                helperText={errors.summary?.message}
                multiline
                rows={4}
              />
              <Typography fontSize={13}>{editSummary.length}/1000</Typography>
            </Grid>
            {user?.languageSpecialization && (
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("summary2ndLanguage", {
                    shouldUnregister: false,
                  })}
                  label={getTranslation(
                    "ResourceIIIBookDescriptionSecondLanguage"
                  )}
                  value={editSummaryBL}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    newValue.length <= 1000 && setEditSummaryBL(newValue);
                  }}
                  fullWidth
                  error={!!errors.summary2ndLanguage?.message}
                  helperText={errors.summary2ndLanguage?.message}
                  multiline
                  rows={4}
                />
                <Typography fontSize={13}>
                  {editSummaryBL.length}/1000
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          display="display"
          justifyContent="space-between"
        >
          <Grid item>
            <LoadingButton
              loading={isSubmitting}
              disabled={!isValid}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              color="comp3"
              variant="contained"
            >
              {getTranslation("SaveBook")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
